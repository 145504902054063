import { mapActions, mapGetters } from "vuex";
import { download_file } from "@/utils/downloadFile.util";
import pdfGenerator from '@/services/pdfGenerator';
import dayjs from 'dayjs';
import commentsMixin from "@/mixins/comments.mixin"
import { getDateFormat } from "@/utils/getDateFormat.util"
import { removeAllFormSubCollections } from '@/services/removeSubCollection.service'
import updateSubCollection from '@/services/updateSubCollection.service'
import getUserById from "@/services/get-user-by-id.service";
import adminCheckerMixin from '@/mixins/adminChecker.mixin'
import updateUser from "@/services/update-user";
import userExists from "@/services/user-exists.service";

export default {
  mixins: [commentsMixin,adminCheckerMixin],
  
  data() {
    return {
      steps: [],
      formLoading: '',
      resetUserId: '',
      pdfToDelete: '',
      pdfGenerating: '',
      loading: true,
      search: '',
      activeCommentHistory: {},
      headers: [
        {
          text: 'Actions',
          value: 'actions',
          align: 'left',
          sortable: false,
        },
        {
          text: '',
          value: 'owner.name',
          align: 'd-none',
          sortable: false,
        },
        {
          text: '',
          value: 'sharingProps[0].user.name',
          sortable: false,
          align: 'd-none'
        },
        {
          text: '',
          value: 'completed',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Form',
          value: 'name',
          sortable: true,
        },
        {
          text: 'Collaborating',
          value: 'collaborate',
          sortable: true,
        },
        {
          text: 'Petitoner',
          value: 'pet_info',
          sortable: true,
        },
        {
          text: 'Respondent',
          value: 'resp_info',
          sortable: true,
        },
        {
          text: 'Step',
          value: 'stepDesc',
          sortable: true,
          align: 'left',
        },
        {
          text: 'ID',
          value: 'id',
        }
      ]
    }
  },

  computed: {
    ...mapGetters(['getFirebaseConfig']),

    ...mapGetters("users", ["usersForCommentHistory"]),

    ...mapGetters("workflowPublished", ["publishedWorkflows"]),

    canResendRespondentEmail() {
      return (item) => item.step == '3' && item.collaborate === 'Yes' && item.sharingProps?.[0]?.user?.email
    },

    isComplete() {
      return (item) => {
        if(item?.collaborate && item.collaborate === 'No') {
          return item.stepDesc.indexOf('7.') === 0
        } else {
          return item.sharingProps[0].agreedWithSpouse
        }        
      }
    },

    menuLoading() {
      return (id) => 
        this.formLoading === id || this.resetUserId === id || this.pdfGenerating === id
    }
  },

  methods: {
    ...mapActions("alert", ["setAlert"]),
    ...mapActions(["toggleLoadingOverlayStatus"]),
    ...mapActions("users", ["getUsersForCommentHistory","removeForms"]),
    ...mapActions("workflowPublished", [
      "setFinalPDF",
      "setPublishedWorkflows",
      "getSubCollection",
      "updateRawForm",
      "clearForm",
      'saveFormProperty',
      'deletePublishedWorkflow',
      'sendEmailToRespondent'
    ]),
    ...mapActions("comments", ["getCommentHistory"]),
    ...mapActions("confirmation", ["confirm"]),
    
    async resendRespondentEmail(item) {
      this.formLoading = item.id
      const respExists = userExists(item.sharingProps[0].user.email)
      const link = "create-user"
      const qs = `?wfid=${item.id}&petitioner=${item.owner.email}&respondent=${item.sharingProps[0].user.email}`;
      const sharingData = {
        user: {
          name: item.sharingProps[0].user.name,
          email: item.sharingProps[0].user.email
        }
      }
      await this.sendEmailToRespondent({ id: item.id, sharingData, pg: link + qs })
      this.setAlert({ type: 'info', message: 'Successfully resent email to respondent'})
      this.formLoading = null
    },

    redirect(url) {
      location.href = url
    },

    async deleteFinalPdf(item) {
      this.confirm({
        title: 'Are you sure you want to delete this PDF?',
        accepted: async () => {
          this.pdfToDelete = item.id
          item.finalPDF = ''
          await this.saveFormProperty({ formId: item.id, props: { finalPDF: '' }})
          this.pdfToDelete = ''
        }
      })
    },

    async downloadClick(path) {
      const fileName = decodeURIComponent(path).split('/').pop()
      await download_file(path, fileName)
    },

    async restartUserWorkflow(item) {
      this.confirm({
        title: 'Are you sure you want to reset the workflow for ' + item.owner.name + '?',
        accepted: async () => {
          this.resetUserId = item.id
          if(item.collaborate.localeCompare('yes','en',{ sensitivity: 'base' }) === 0 && item?.sharingUser?.length > 0) {
            const respondent_data = {
              petitioner_email: item.owner.email,
              wfid: null
            }
            await updateUser(this.getFirebaseConfig.db, item.sharingUser, { respondent_data })
          }
          await Promise.all([
            this.deletePublishedWorkflow({ id: item.id }),
            this.removeForms(item.owner.email)
          ])
          await this.getUsersForCommentHistory();
          this.resetUserId = ''
        }
      })
    },

    async changeForm(currentItem, dialog, newParentId) {
      this.confirm({
        title: 'Are you sure you want to change the questionnaire?',
        accepted: async () => {
          dialog.value = false
          this.formLoading = currentItem.id
          const selectedParent = this.publishedWorkflows.find(p => p.id === newParentId)
          const pet_name = currentItem.owner.name.split(', ')
          if(currentItem.sharingProps[0].user.name === 'Not Registered') {
            currentItem.sharingProps = []
          } else {
            const resp_name = currentItem.sharingProps[0].user.name.split(', ')
            currentItem.sharingProps[0].user.name = `${resp_name[1]} ${resp_name[0]}`
          }
          currentItem.step = 1
          const updatedForm = {
            ...selectedParent,
            step: 1,
            owner: {
              name: `${pet_name[1]} ${pet_name[0]}`,
              email: currentItem.owner.email
            },
            sharingProps: currentItem.sharingProps,
            sharingUser: currentItem.sharingUser,
            respondentStep: 1,
            parentId: newParentId,
            id: currentItem.id
          }
          currentItem.name = selectedParent.name
          await Promise.all([
            this.updateRawForm({ formId: currentItem.id, data: updatedForm }),
            removeAllFormSubCollections({ id: currentItem.id, parent: 'workflows-user' })
          ])
          const subs = ['docs','blocks','fields','display-logic-elements','display-logic-library']
          const toUpdate = []
          const userCommentHistory = []
          for(let s = 0; s < subs.length; s++) {
            const sub = subs[s]
            let data = await this.getSubCollection({ parent: 'workflows-published', id: newParentId, sub })
            if(sub === 'fields') {
              data = await this.clearForm({ fields: data })
            }
            toUpdate.push(updateSubCollection({ parent: 'workflows-user', id: currentItem.id, sub, data }))
            userCommentHistory.push(this.getUsersForCommentHistory())
          }
          await Promise.all(toUpdate)
          await Promise.all(userCommentHistory)
          setTimeout(() => {        
            this.formLoading = ''
          },1000)
        }
      })
    },

    async generateDoc(formData) {
      this.pdfGenerating = formData.id
      const { full_name, petitioner_data } = await getUserById(formData.owner.email)
      const fileName = encodeURIComponent(`SIMPLEENDING-${petitioner_data.respondent_first_name}-and-${full_name.replace(' ', '-')}-${getDateFormat(dayjs(),'MMDDYYYY')}`)
      formData.finalPDF = await pdfGenerator().run(formData.id, fileName)
      this.pdfGenerating = ''
    },

    async getUserCommentHistory(item) {
      this.toggleLoadingOverlayStatus(true);
      this.activeCommentHistory = await this.getCommentHistory(item.id);
      this.toggleLoadingOverlayStatus(false);
    },
  },

  async mounted() {
    await Promise.all([
      this.getUsersForCommentHistory(),
      this.setPublishedWorkflows()
    ])
    this.loading = false;
  },
}