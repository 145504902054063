var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"full-height",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"text-center"},[_vm._v("Paywall management")])])],1),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"px-4"},[_c('v-select',{attrs:{"items":[
            _vm.constantValue.WITHOUT_SPOUSE_GENERAL_PREVIEW,
            _vm.constantValue.WITHOUT_SPOUSE_LEVEL_ONE_PREVIEW,
            _vm.constantValue.WITHOUT_SPOUSE_LEVEL_TWO_PREVIEW,
           ],"label":"Choose Card Preview","outlined":""},on:{"input":_vm.selectedCardValuePreview},model:{value:(_vm.selectedCard),callback:function ($$v) {_vm.selectedCard=$$v},expression:"selectedCard"}})],1),(_vm.selectedCard === _vm.constantValue.WITHOUT_SPOUSE_LEVEL_ONE_PREVIEW)?_c('CostInfo',{attrs:{"price":_vm.items[1]?.level_1?.price,"show-select-btn":true,"image":_vm.items[1]?.level_1?.img,"summary":_vm.items[1]?.level_1?.summary,"tab":{ title: _vm.items[1]?.level_1?.title, bg: _vm.items[1]?.level_1?.bg, fg: _vm.items[1]?.level_1?.fg },"items":_vm.items[1]?.level_1?.items,"tab-items":[
          _vm.constantValue.WITHOUT_SPOUSE_GENERAL,
          _vm.constantValue.WITHOUT_SPOUSE_LEVEL_ONE,
          _vm.constantValue.WITHOUT_SPOUSE_LEVEL_TWO
          ],"unilateral-pricing-levels":_vm.unilateralPricing,"nameProp":"unilateral","preview-choice":_vm.constantValue.WITH_SPOUSE_LEVEL_ONE_PREVIEW,"btn-name":"Edit card"},on:{"dialogAction":_vm.editForm}}):(_vm.selectedCard === _vm.constantValue.WITHOUT_SPOUSE_LEVEL_TWO_PREVIEW)?_c('CostInfo',{attrs:{"price":_vm.items[1]?.level_2?.price,"show-select-btn":true,"image":_vm.items[1]?.level_2?.img,"summary":_vm.items[1]?.level_2?.summary,"tab":{ title: _vm.items[1]?.level_2?.title, bg: _vm.items[1]?.level_2?.bg, fg: _vm.items[1]?.level_2?.fg },"items":_vm.items[1]?.level_2?.items,"tab-items":[
          _vm.constantValue.WITHOUT_SPOUSE_GENERAL,
          _vm.constantValue.WITHOUT_SPOUSE_LEVEL_ONE,
          _vm.constantValue.WITHOUT_SPOUSE_LEVEL_TWO
         ],"unilateral-pricing-levels":_vm.unilateralPricing,"nameProp":"unilateral","preview-choice":_vm.constantValue.WITHOUT_SPOUSE_LEVEL_TWO_PREVIEW,"btn-name":"Edit card"},on:{"dialogAction":_vm.editForm}}):_c('CostInfo',{attrs:{"price":_vm.items[1]?.WithoutSpouse?.price,"show-select-btn":true,"image":_vm.items[1]?.WithoutSpouse?.img,"summary":_vm.items[1]?.WithoutSpouse?.summary,"tab":{ title: _vm.items[1]?.WithoutSpouse?.title, bg: _vm.items[1]?.WithoutSpouse?.bg, fg: _vm.items[1]?.WithoutSpouse?.fg },"items":_vm.items[1]?.WithoutSpouse?.items,"tab-items":[
          _vm.constantValue.WITHOUT_SPOUSE_GENERAL,
          _vm.constantValue.WITHOUT_SPOUSE_LEVEL_ONE,
          _vm.constantValue.WITHOUT_SPOUSE_LEVEL_TWO
         ],"unilateral-pricing-levels":_vm.unilateralPricing,"nameProp":"unilateral","preview-choice":_vm.constantValue.WITHOUT_SPOUSE_GENERAL_PREVIEW,"btn-name":"Edit card"},on:{"dialogAction":_vm.editForm}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"px-4"},[_c('v-select',{attrs:{"items":[
            _vm.constantValue.WITH_SPOUSE_GENERAL_PREVIEW,
            _vm.constantValue.WITH_SPOUSE_LEVEL_ONE_PREVIEW,
            _vm.constantValue.WITH_SPOUSE_LEVEL_TWO_PREVIEW
            ],"label":"Choose Card Preview","outlined":""},on:{"input":_vm.selectedColCardValuePreview},model:{value:(_vm.selectedColCard),callback:function ($$v) {_vm.selectedColCard=$$v},expression:"selectedColCard"}})],1),(_vm.selectedColCard === _vm.constantValue.WITH_SPOUSE_LEVEL_ONE_PREVIEW)?_c('CostInfo',{attrs:{"price":_vm.items[0]?.level_1?.price,"show-select-btn":true,"image":_vm.items[0]?.level_1?.img,"summary":_vm.items[0]?.level_1?.summary,"tab":{ title: _vm.items[0]?.level_1?.title, bg: _vm.items[0]?.level_1?.bg, fg: _vm.items[0]?.level_1?.fg },"items":_vm.items[0]?.level_1?.items,"tab-items":[
          _vm.constantValue.WITH_SPOUSE_GENERAL,
          _vm.constantValue.WITH_SPOUSE_LEVEL_ONE,
          _vm.constantValue.WITH_SPOUSE_LEVEL_TWO],"collaborative-pricing-levels":_vm.collaborativePricing,"nameProp":"collaborative","btn-name":"Edit card","preview-choice":_vm.constantValue.WITH_SPOUSE_LEVEL_ONE_PREVIEW},on:{"dialogAction":_vm.editForm}}):(_vm.selectedColCard === _vm.constantValue.WITH_SPOUSE_LEVEL_TWO_PREVIEW)?_c('CostInfo',{attrs:{"price":_vm.items[0]?.level_2?.price,"show-select-btn":true,"image":_vm.items[0]?.level_2?.img,"summary":_vm.items[0]?.level_2?.summary,"tab":{ title: _vm.items[0]?.level_2?.title, bg: _vm.items[0]?.level_2?.bg, fg: _vm.items[0]?.level_2?.fg },"items":_vm.items[0]?.level_2?.items,"tab-items":[
          _vm.constantValue.WITH_SPOUSE_GENERAL,
          _vm.constantValue.WITH_SPOUSE_LEVEL_ONE,
          _vm.constantValue.WITH_SPOUSE_LEVEL_TWO],"collaborative-pricing-levels":_vm.collaborativePricing,"nameProp":"collaborative","btn-name":"Edit card","preview-choice":_vm.constantValue.WITH_SPOUSE_LEVEL_TWO_PREVIEW},on:{"dialogAction":_vm.editForm}}):_c('CostInfo',{attrs:{"price":_vm.items[0]?.WithSpouse?.price,"show-select-btn":true,"image":_vm.items[0]?.WithSpouse?.img,"summary":_vm.items[0]?.WithSpouse?.summary,"tab":{ title: _vm.items[0]?.WithSpouse?.title, bg: _vm.items[0]?.WithSpouse?.bg, fg: _vm.items[0]?.WithSpouse?.fg },"items":_vm.items[0]?.WithSpouse?.items,"tab-items":[
          _vm.constantValue.WITH_SPOUSE_GENERAL,
          _vm.constantValue.WITH_SPOUSE_LEVEL_ONE,
          _vm.constantValue.WITH_SPOUSE_LEVEL_TWO],"collaborative-pricing-levels":_vm.collaborativePricing,"nameProp":"collaborative","btn-name":"Edit card","preview-choice":_vm.constantValue.WITH_SPOUSE_GENERAL_PREVIEW},on:{"dialogAction":_vm.editForm}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }