<template>
  <v-container fluid class="full-height">
    <v-row>
      <v-col cols="12">
        <h2 class="text-center">Paywall management</h2>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="4">
        <div class="px-4">
          <v-select
            :items="[
              constantValue.WITHOUT_SPOUSE_GENERAL_PREVIEW,
              constantValue.WITHOUT_SPOUSE_LEVEL_ONE_PREVIEW,
              constantValue.WITHOUT_SPOUSE_LEVEL_TWO_PREVIEW,
             ]"
            label="Choose Card Preview"
            v-model="selectedCard"
            @input="selectedCardValuePreview"
            outlined
          ></v-select>
        </div>
        <CostInfo
          v-if="selectedCard === constantValue.WITHOUT_SPOUSE_LEVEL_ONE_PREVIEW"
          :price="items[1]?.level_1?.price"
          :show-select-btn="true"
          :image="items[1]?.level_1?.img"
          :summary="items[1]?.level_1?.summary"
          :tab="{ title: items[1]?.level_1?.title, bg: items[1]?.level_1?.bg, fg: items[1]?.level_1?.fg }"
          :items="items[1]?.level_1?.items"
          :tab-items="[
            constantValue.WITHOUT_SPOUSE_GENERAL,
            constantValue.WITHOUT_SPOUSE_LEVEL_ONE,
            constantValue.WITHOUT_SPOUSE_LEVEL_TWO
            ]"
          :unilateral-pricing-levels="unilateralPricing"
          nameProp="unilateral"
          :preview-choice="constantValue.WITH_SPOUSE_LEVEL_ONE_PREVIEW"
          btn-name="Edit card"
          @dialogAction="editForm"
        />
        <CostInfo
          v-else-if="selectedCard === constantValue.WITHOUT_SPOUSE_LEVEL_TWO_PREVIEW"
          :price="items[1]?.level_2?.price"
          :show-select-btn="true"
          :image="items[1]?.level_2?.img"
          :summary="items[1]?.level_2?.summary"
          :tab="{ title: items[1]?.level_2?.title, bg: items[1]?.level_2?.bg, fg: items[1]?.level_2?.fg }"
          :items="items[1]?.level_2?.items"
          :tab-items="[
            constantValue.WITHOUT_SPOUSE_GENERAL,
            constantValue.WITHOUT_SPOUSE_LEVEL_ONE,
            constantValue.WITHOUT_SPOUSE_LEVEL_TWO
           ]"
          :unilateral-pricing-levels="unilateralPricing"
          nameProp="unilateral"
          :preview-choice="constantValue.WITHOUT_SPOUSE_LEVEL_TWO_PREVIEW"
          btn-name="Edit card"
          @dialogAction="editForm"
        />
        <CostInfo
          v-else
          :price="items[1]?.WithoutSpouse?.price"
          :show-select-btn="true"
          :image="items[1]?.WithoutSpouse?.img"
          :summary="items[1]?.WithoutSpouse?.summary"
          :tab="{ title: items[1]?.WithoutSpouse?.title, bg: items[1]?.WithoutSpouse?.bg, fg: items[1]?.WithoutSpouse?.fg }"
          :items="items[1]?.WithoutSpouse?.items"
          :tab-items="[
            constantValue.WITHOUT_SPOUSE_GENERAL,
            constantValue.WITHOUT_SPOUSE_LEVEL_ONE,
            constantValue.WITHOUT_SPOUSE_LEVEL_TWO
           ]"
          :unilateral-pricing-levels="unilateralPricing"
          nameProp="unilateral"
          :preview-choice="constantValue.WITHOUT_SPOUSE_GENERAL_PREVIEW"
          btn-name="Edit card"
          @dialogAction="editForm"
        />
      </v-col>
      <v-col cols="4">
        <div class="px-4">
          <v-select
            :items="[
              constantValue.WITH_SPOUSE_GENERAL_PREVIEW,
              constantValue.WITH_SPOUSE_LEVEL_ONE_PREVIEW,
              constantValue.WITH_SPOUSE_LEVEL_TWO_PREVIEW
              ]"
            label="Choose Card Preview"
            v-model="selectedColCard"
            @input="selectedColCardValuePreview"
            outlined
          ></v-select>
        </div>
        <CostInfo
          v-if="selectedColCard === constantValue.WITH_SPOUSE_LEVEL_ONE_PREVIEW"
          :price="items[0]?.level_1?.price"
          :show-select-btn="true"
          :image="items[0]?.level_1?.img"
          :summary="items[0]?.level_1?.summary"
          :tab="{ title: items[0]?.level_1?.title, bg: items[0]?.level_1?.bg, fg: items[0]?.level_1?.fg }"
          :items="items[0]?.level_1?.items"
          :tab-items="[
            constantValue.WITH_SPOUSE_GENERAL,
            constantValue.WITH_SPOUSE_LEVEL_ONE,
            constantValue.WITH_SPOUSE_LEVEL_TWO]"
          :collaborative-pricing-levels="collaborativePricing"
          nameProp="collaborative"
          btn-name="Edit card"
          :preview-choice="constantValue.WITH_SPOUSE_LEVEL_ONE_PREVIEW"
          @dialogAction="editForm"
        />
        <CostInfo
          v-else-if="selectedColCard === constantValue.WITH_SPOUSE_LEVEL_TWO_PREVIEW"
          :price="items[0]?.level_2?.price"
          :show-select-btn="true"
          :image="items[0]?.level_2?.img"
          :summary="items[0]?.level_2?.summary"
          :tab="{ title: items[0]?.level_2?.title, bg: items[0]?.level_2?.bg, fg: items[0]?.level_2?.fg }"
          :items="items[0]?.level_2?.items"
          :tab-items="[
            constantValue.WITH_SPOUSE_GENERAL,
            constantValue.WITH_SPOUSE_LEVEL_ONE,
            constantValue.WITH_SPOUSE_LEVEL_TWO]"
          :collaborative-pricing-levels="collaborativePricing"
          nameProp="collaborative"
          btn-name="Edit card"
          :preview-choice="constantValue.WITH_SPOUSE_LEVEL_TWO_PREVIEW"
          @dialogAction="editForm"
        />
        <CostInfo
          v-else
          :price="items[0]?.WithSpouse?.price"
          :show-select-btn="true"
          :image="items[0]?.WithSpouse?.img"
          :summary="items[0]?.WithSpouse?.summary"
          :tab="{ title: items[0]?.WithSpouse?.title, bg: items[0]?.WithSpouse?.bg, fg: items[0]?.WithSpouse?.fg }"
          :items="items[0]?.WithSpouse?.items"
          :tab-items="[
            constantValue.WITH_SPOUSE_GENERAL,
            constantValue.WITH_SPOUSE_LEVEL_ONE,
            constantValue.WITH_SPOUSE_LEVEL_TWO]"
          :collaborative-pricing-levels="collaborativePricing"
          nameProp="collaborative"
          btn-name="Edit card"
          :preview-choice="constantValue.WITH_SPOUSE_GENERAL_PREVIEW"
          @dialogAction="editForm"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CostInfo from "@/components/CostInfo.vue";
import {PRICING_CARD_DROPDOWN_OPTIONS} from "@/constants/cardPrices";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {CostInfo},

  data() {
    return {
      tab: 0,
      currentCategory: '',
      items: [],
      tabTitles: [],
      variant: '',
      collaborativeData: {},
      unilateralData: {},
      selectedCard: '',
      selectedColCard: '',
      collaborativePricing: {
        total: 0,
        levelOne: 0,
        levelTwo: 0,
      },
      unilateralPricing: {
        total: 0,
        levelOne: 0,
        levelTwo: 0,
      }
    }
  },

  computed: {
    ...mapGetters({
      prices: 'prices/getCardCosts'
    }),
    constantValue() {
      return PRICING_CARD_DROPDOWN_OPTIONS;
    },
  },

  methods: {
    ...mapActions({
      getAllPrices: 'prices/getPrices',
      saveItems: 'prices/saveListItems'
    }),
    editForm(data) {
      this.saveItems(data)
    },
    selectedCardValuePreview(value) {
      this.selectedCard = value
    },
    selectedColCardValuePreview(value) {
      this.selectedColCard = value
    }
  },

  async mounted() {
    await this.getAllPrices()
    this.items = [this.prices[0], this.prices[1]];
    this.unilateralData = this.prices[1]
    this.collaborativeData = this.prices[0]
    this.collaborativePricing.total = this.prices[0].WithSpouse?.price
    this.collaborativePricing.levelOne = this.prices[0].level_1?.price
    this.collaborativePricing.levelTwo = this.prices[0].level_2?.price
    this.unilateralPricing.total = this.prices[1].WithoutSpouse?.price
    this.unilateralPricing.levelOne = this.prices[1].level_1?.price
    this.unilateralPricing.levelTwo = this.prices[1].level_2?.price
  }
}
</script>
