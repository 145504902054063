<template>
  <v-container fluid class="full-height">
    <v-row>
      <v-col cols="12" md="8">
        <v-card elevation="4">
          <v-card-title class="bg-primary white--text">
            Child Support Calculator
          </v-card-title>

          <v-card-text>
            <v-row class="mt-2">
              <v-col cols="10" md="6" class="d-flex justify-center align-center">
                <v-select
                  @input="getInfo"
                  :items="states"
                  v-model="selectedState"
                  item-text="state"
                  return-object
                  label="Select the State"
                  outlined
                  style="height: 48px"
                />
              </v-col>

              <v-col cols="1" class="d-flex align-center">
                <v-btn :disabled="!selectedState" title="Delete Document" color="red" fab small @click="showDeleteModal = true" class="mr-2">
                  <v-icon small dark color="white"> mdi-close </v-icon>
                </v-btn>

                <v-dialog v-model="newStateModal" width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn title="Add New State" color="success" dark fab small v-bind="attrs" v-on="on">
                      <v-icon small dark color="white"> mdi-plus </v-icon>
                    </v-btn>
                  </template>

                  <v-card class="pt-5 text-left">
                    <v-card-title class="headline">
                      <v-icon color="primary" class="mr-2">error</v-icon>
                      Add New State?
                    </v-card-title>

                    <v-card-text class="pt-5 ">
                      <p>State you are looking for is not listed? <strong>Create new one here.</strong> </p>
                      <v-text-field
                        v-model="newState"
                        label="State"
                        outlined
                        class="mt-5"
                      ></v-text-field>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-btn color="primary" text @click="newStateModal = false">
                        Close
                      </v-btn>
                      <v-spacer />
                      <v-btn @click="addNewState" class="primary" text>
                        Add State
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>

            <v-row style="text-align: left">
              <v-col cols="12">
                <v-divider />
              </v-col>

              <v-col cols="12" lg="8">
                <label>Enter the user-facing hyperlink</label>
                <v-text-field
                  v-model="hyperlink"
                  label="Hyperlink"
                  placeholder="Click here for the hyperlink"
                  class="mt-3"
                  outlined
                />

                <label>Enter the path to coresponding calculator</label>
                <v-text-field
                  v-model="website"
                  label="Child Support Calculator URL"
                  placeholder="https://www.example.com"
                  class="mt-3"
                  outlined
                />

                <label>Enter additional description if needed</label>
                <quill-editor
                  v-model="description"
                  :options="editorOption"
                  style="height: 300px"
                  class="o-quill-editor overflow-y-auto border-solid-1 mt-2"
                />
              </v-col>
            </v-row>

            <v-row style="text-align: left">
              <v-col>
                <v-btn color="primary" @click="save" :disabled="!selectedState">
                  Apply Changes
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card elevation="4" style="text-align: left; position: sticky; top: 75px;">
          <v-card-title class="bg-primary white--text">
            User Preview
          </v-card-title>
          <v-card-text class="mt-4">
            <h3>Child Support Calculator</h3>
            <p class="mt-3 mb-2" v-html="description"></p>
            <a :href="website" target="_blank">{{ hyperlink }}</a>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


    <v-dialog v-model="showDeleteModal" :retain-focus="false" max-width="500">
      <v-card class="pa-3">
        <v-card-title class="headline">
          <v-icon color="error" class="mr-2">error</v-icon>
          Delete data for the {{ selectedState }} ?
        </v-card-title>

        <v-card-text>
          Are you sure you want to delete this data? <br />
          <strong>These changes will be permanent</strong>.
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" text @click="showDeleteModal = false">
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn @click="removeState" class="error" text>
            Yes, Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <div class="text-center">
      <v-snackbar
        v-model="snackbar"
        timeout="3000"
        elevation="3"
        :color="color"
        absolute
        bottom
        right
      >
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            <v-icon small color="white"> close </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-container>
</template>

<script>
import {quillEditor} from "vue-quill-editor";
import {QUILL_MODULES, QUILL_MODULES_CC} from "@/constants/optionsConstants";
import {mapActions} from "vuex";
import adminCheckerMixin from '@/mixins/adminChecker.mixin'

export default {
  name: "ChildSupportCalculator",

  mixins: [adminCheckerMixin],

  components: {
    quillEditor,
  },

  data() {
    return {
      states: [],
      selectedState: '',
      newState: '',
      description: '',
      website: '',
      hyperlink: '',
      showDeleteModal: false,
      newStateModal: false,
      editorOption: {
        modules: QUILL_MODULES_CC,
      },
      snackbar: false,
      text: 'Data saved to database!',
      color: 'success',
    };
  },

  created() {
    this.getStates();
    this.getInfo();
  },

  methods: {
    ...mapActions({
      saveCC: 'childCalculator/saveCCLogic',
      getCC: 'childCalculator/getCCLogic',
      countries: 'childCalculator/getCountries',
      addState: 'childCalculator/addNewStateToCC',
      removeExistingState: 'childCalculator/removeStateFromCC',
    }),

    async getStates() {
      this.states = await this.countries();
    },

    async getInfo() {
      const response = await this.getCC(this.selectedState);
      this.description = response.description;
      this.website = response.website;
      this.hyperlink = response.hyperlink;
    },

    async addNewState() {
      const response = await this.addState(this.newState);
      if(response === 'success') {
        this.snackbar = true;
        this.newStateModal = false;
        this.newState = '';
        this.color = 'success';
        this.text = `State ${this.newState} added!`;
        await this.getStates();
      }
      if(response === 'error') {
        this.snackbar = true;
        this.newStateModal = false;
        this.newState = '';
        this.color = 'error';
        this.text = 'Unable to add new state';
      }
    },

    async removeState() {
      const response = await this.removeExistingState(this.selectedState);
      if(response === 'success') {
        this.snackbar = true;
        this.color = 'success';
        this.showDeleteModal = false;
        this.text = 'State removed successfuly!';
        await this.getStates();
      }
      if(response === 'error') {
        this.snackbar = true;
        this.color = 'error';
        this.showDeleteModal = false;
        this.text = 'Something went wrong!';
      }
    },

    async save() {
      const template = {
        state: this.selectedState,
        description: this.description,
        website: this.website,
        hyperlink: this.hyperlink,
      };
      const response = await this.saveCC(template);

      if(response === 'success') {
        this.snackbar = true;
        this.color = 'success';
        this.text = 'Data updated successfuly!';
      }
      if(response === 'error') {
        this.snackbar = true;
        this.color = 'error';
        this.text = 'Something went wrong!';
      }
    },
  },
};
</script>
